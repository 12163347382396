.AppNew {
  
  height: auto;
  /* max-height: 80vh; */
  /* background-color: #C6F4A9;
  background-image: linear-gradient(231.59deg, rgba(233, 250, 239, 0) 40%, rgba(233, 250, 239, 0.5) 100.44%), linear-gradient(180deg, rgba(233, 250, 239, 0.5) 55.38%, #E9FAEF 100%);
  background-attachment: fixed; */

  width: 100vw;

  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);

  overflow: visible


}

.App-logo {
  width: 20vw;
  pointer-events: none;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

/* .App-header {
 
} */

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.backIconSocial {
  display: block;
  width: 4.2rem;
  height: 4.2rem;
  opacity: 0.4;
  border-radius: 10.7px;
  background: #1ce5ff;
  background: linear-gradient(331deg, #108493 11%, #1ce5ff 89%);
}
.modSocial {

  display: flex;
  flex-direction: row;
  display: inline-block;
  margin: 3rem 0;
  padding: 2rem 1.6rem;
  border-radius: 16px;
  box-shadow: 2px 2px 12px 0 rgba(0, 0, 0, 0.5);
  background-color: #444452;
}
.iconSocial {
  display: block;
  width: 4.2rem;
  height: 4.2rem;
  position: relative;
}

.popup-btn{
  opacity: 0.8;
  cursor: pointer;
}
.popup-btn:hover {
 opacity: 1;
}

.video-popup {
  display: none;
  width: 100%;
  height: 100vh;
  margin: auto;
  position: fixed;
  top: 0;
  /* boxShadow: 10px 10px 10px 10px black; */
}
.appear{
  display: block;
  
  animation: normal 0.5s appearanimation;
}
@keyframes appearanimation{

from{
  opacity: 0;
}
to {
opacity: 1;
}
}

.popup-bg {
  background: rgba(0,0,0,0.8);
  width: 100%;
  height: 100vh;
  position: absolute;
}
.popup-content {
  background: black;
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column ;
  top: 61px;
  align-items: center;

}
.video{
  height: 70vh;
  width: 70vw;
}